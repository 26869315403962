import { ComponentProps } from 'react'

import { cn } from '@Shared/helpers/util'

import { RATING_MAP, RatingKeys } from './data'

const hasAutoRating = (rating: string): boolean =>
  rating?.charAt(0).toLowerCase() === 'a'

export const getRatingKey = (rating: string, key?: RatingKeys): string => {
  const contentRating = hasAutoRating(rating) ? rating.substring(1) : rating

  const ratingKey = contentRating
    ? contentRating?.replace(/livre|^l$/i, 'l').replace(/\sanos/i, '')
    : 'default'

  return (
    key &&
    (RATING_MAP[ratingKey]
      ? RATING_MAP[ratingKey]?.[key]
      : RATING_MAP.default?.[key])
  )
}

type ParentalRatingProps = ComponentProps<'div'> & {
  rating: string
}

type ParentalRatingRootProps = ComponentProps<'div'>

const Root = ({ className, ...props }: ParentalRatingRootProps) => (
  <div className={cn('flex items-baseline text-xs', className)} {...props}>
    {props.children}
  </div>
)

export const Icon = ({ className, rating }: ParentalRatingProps) => {
  const bgColor = getRatingKey(rating, 'color')
  const label = hasAutoRating(rating) ? rating : getRatingKey(rating, 'label')

  return (
    <div
      className={cn(
        bgColor,
        'inline-flex h-[19px] w-[19px] items-center justify-center rounded text-center text-[7.5px] font-bold uppercase text-white',
        className
      )}
    >
      {label}
    </div>
  )
}

const Description = ({ rating }: ParentalRatingProps) => {
  const description = getRatingKey(rating, 'description')

  return <p>{description}</p>
}

const ParentalRating = {
  Root,
  Icon,
  Description
}

export default ParentalRating
