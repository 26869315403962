'use client'

import { cva, VariantProps } from 'class-variance-authority'
import { useMedia } from 'react-use'

import { Event } from '@Schemas/domain/event'
import { TTheater } from '@Schemas/domain/theater'

import UOLAdContainer from '@Components/ad-banner/uol-ads'

const banners = cva(['lg:whitespace-normal', 'lg:overflow-visible'], {
  variants: {
    variant: {
      column: [
        'lg:row-start-[none]',
        'lg:row-end-[none]',
        '[&_#div-gpt-ad-square-left]:pb-8',
        '[&_#div-gpt-ad-square-center]:pb-8',
        '[&_#div-gpt-ad-square-right]:pb-8'
      ],
      row: [
        'flex',
        'my-5',
        'place-content-evenly',
        'mx-0',
        '[&_div]:max-w-[300px]'
      ]
    }
  },
  defaultVariants: {
    variant: 'column'
  }
})

type AdBannersProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof banners> & {
    movie?: Event
    theater?: TTheater
  }

const AdBanners = ({ className, variant, movie, theater }: AdBannersProps) => {
  const isMobile = useMedia('(max-width: 600px)')

  return (
    <div className={banners({ variant, className })}>
      {isMobile ? (
        <UOLAdContainer
          id="div-gpt-ad-square-left"
          movie={movie}
          theater={theater}
        />
      ) : (
        <>
          <UOLAdContainer
            id="div-gpt-ad-square-left"
            movie={movie}
            theater={theater}
          />
          <UOLAdContainer
            id="div-gpt-ad-square-center"
            movie={movie}
            theater={theater}
          />
          <UOLAdContainer
            id="div-gpt-ad-square-right"
            movie={movie}
            theater={theater}
          />
        </>
      )}
    </div>
  )
}

export default AdBanners
