import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '@Shared/helpers/util'

const tag = cva(
  [
    'inline-block',
    'py-1',
    'px-2',
    'uppercase',
    'h-full',
    'font-bold',
    'leading-none',
    'text-[10px]',
    'rounded-[5px]',
    'tracking-wider',
    'lg:py-1',
    'lg:px-4',
    'lg:leading-snug'
  ],
  {
    variants: {
      variant: {
        default: ['bg-ing-blue'],
        outline: ['bg-transparent', 'border', 'border-solid']
      },
      withColor: {
        default: ['text-ing-neutral'],
        green: ['text-[#00A651]', 'border-[#00A651]'],
        yellow: ['bg-ing-yellow-400', 'text-[#000]'],
        neutral: ['bg-ing-neutral', 'text-ing-blue', 'border-ing-neutral']
      }
    },
    defaultVariants: {
      variant: 'default',
      withColor: 'default'
    }
  }
)

interface TagProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof tag> {
  name: string
}

const Tag = ({ className, name, variant, withColor, ...props }: TagProps) => {
  return (
    <div className={cn(tag({ variant, withColor, className }))} {...props}>
      {name}
    </div>
  )
}

export default Tag
