import { useQuery } from '@tanstack/react-query'

import contentAPI from '@Services/contentAPI'

const queryKeyGeneric = (type?: string) =>
  type ? ['genericCollection', type] : ['genericCollections']

const fetchGenericCollections = async (type?: string) => {
  const baseUrl = '/v0/carousel/generics'
  const byType = type ? `?carousels=${type}` : ''
  const { data } = await contentAPI.get(`${baseUrl}${byType}`)
  return type ? data[0] : data
}

export const useGenericCollectionQuery = (type?: string, options?: any) =>
  useQuery({
    queryKey: queryKeyGeneric(type),
    queryFn: () => fetchGenericCollections(type),
    ...options
  })
